.collaborations-container {
  margin-top: -20px;
  margin-bottom: 55px;
  .small-container {
    width: 1200px;
    margin: 0 auto;
    .collaboration-wrapper {
      display: flex;
      margin-top: 57px;
      min-height: 387px;
      border-radius: 8px;
      background-color: white;
      .left {
        position: relative;
        width: 516px;
        overflow: hidden;
        height: inherit;
        text-align: left;
        cursor: pointer;
        .cover {
          position: absolute;
          z-index: 0;
          width: 516px;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 57px 30px 0px 30px;
        .collaboration-info {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .collaboration-name {
            max-width: 440px;
            font-size: 36px;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            color: #3d3d3d;
          }
          .join-wrapper {
            display: flex;
            align-items: center;
            .ele-wrapper {
              display: flex;
              align-items: center;
              gap: 5px;
              font-size: 20px;
              color: #f9ba48;
              img {
                width: 16px;
              }
            }
          }
        }
        .owner-info {
          align-self: flex-end;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 40px 0;
          width: 100%;
          div:first-child {
            flex-grow: 1;
            text-align: right;
          }
          .nickname {
            max-width: 100px;
            margin-left: 10px;
            color: #7b7b7b;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .date {
            color: #9b9b9b;
            margin-left: 12px;
          }
        }
        .followed-users {
          .pc-users {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
          }
          .mp-users {
            display: none;
          }
          .more-users {
            position: relative;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: #f0f0f0;
            color: #7b7b7b;
            font-size: 30px;
            &::after {
              content: "...";
              position: absolute;
              font-size: 26px;
              left: 50%;
              transform: translate(-50%, -15%);
            }
          }
          .total-count {
            font-size: 16px;
            // font-weight: 500;
            font-weight: 400;
            span {
              color: #f9ba48;
            }
          }
        }
        .line {
          display: none;
        }
        .hide-in-pc {
          display: none;
        }
      }
    }
    .collaboration-wrapper-reverse {
      flex-direction: row-reverse;
      .tag-wrapper {
        justify-content: end;
      }
    }
  }
}

.collaboration-tag-wrapper {
  display: flex;
  column-gap: 10px;
  position: relative;
  z-index: 1;
  padding: 20px;
  color: white;
  font-size: 16px;
  // font-weight: 500;
  font-weight: 400;
  .tag {
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
    align-items: center;
    padding: 2px 7px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.35);
    img {
      width: 20px;
    }
    .small {
      display: none;
    }
  }
}
.ant-progress-steps-item {
  width: 10px !important;
  height: 3px !important;
  border-radius: 30%;
}

.collaboration-name {
  color: white;
}

@media screen and (max-width: 1175px) {
  .collaborations-container {
    margin-bottom: 25px;
    .small-container {
      width: 100%;
      padding: 0 15px;

      .collaboration-wrapper {
        margin-top: 12px;
        width: 100%;
        min-height: 120px;
        .left {
          width: 88px;
          min-height: 120px;

          .hide-in-mp {
            display: none;
          }
          .cover {
            width: 88px;
          }
        }
        .right {
          padding: 10px;
          width: auto;
          .collaboration-info {
            .collaboration-name {
              font-size: 16px;
              white-space: break-spaces;
              white-space: pre-wrap;
            }
            .join-wrapper {
              .ele-wrapper {
                font-size: 14px;
                gap: 3px;
                img {
                  width: 12px;
                }
              }
            }
          }
          .owner-info {
            display: none;
          }
          .followed-users {
            margin-top: 10px;
            .pc-users {
              display: none;
            }
            .mp-users {
              display: flex;
              align-items: center;
              column-gap: 5px;
            }
            .more-users {
              width: 22px;
              height: 22px;
              font-size: 10px;
              &::after {
                font-size: 15px;
                left: 50%;
                transform: translate(-50%, -20%);
              }
            }
            .total-count {
              font-size: 12px;
              font-weight: 400;
              span {
                color: #f9ba48;
              }
            }
          }
          .line {
            display: block;
            width: 100%;
            height: 1px;
            background-color: #f0f0f0;
            margin: 9px 0;
          }
          .hide-in-pc {
            display: flex;
          }
        }
      }
      .collaboration-wrapper-reverse {
        flex-direction: row;
      }
    }
  }
  .collaboration-tag-wrapper {
    font-size: 11px;
    flex-wrap: wrap;
    padding: 10px 5px;
    color: #e89b11;
    column-gap: 5px;
    padding: 0px;
    .tag {
      background-color: rgba(249, 186, 72, 0.1);
      border-radius: 4px;
      padding: 2px;
      column-gap: 0;
      img {
        width: 12px;
        margin-right: 5px;
      }
      .normal {
        display: none;
      }
      .small {
        display: block;
      }
    }
  }
  .ant-progress-steps-item {
    width: 7px !important;
    height: 2px !important;
    border-radius: 20%;
  }
  .collaboration-name {
    font-size: 24px;
  }
}
